#banner {
  background-image: url("../../../../../src/Image.png");
  background-size: cover;
  animation: slider 20s infinite linear;
}

@keyframes slider {
  0%,
  12% {
    background-image: url("../../../../../src/Image.png");
  }
  14%,
  26% {
    background-image: url("../../../../../src/image_2.png");
  }
  28%,
  40% {
    background-image: url("../../../../../src/image_4.png");
  }
  42%,
  54% {
    background-image: url("../../../../../src/image_5.png");
  }
  56%,
  78% {
    background-image: url("../../../../../src/image_6.png");
  }
  80%,
  92% {
    background-image: url("../../../../../src/image_7.png");
  }
  94%,
  100% {
    background-image: url("../../../../../src/image_3.png");
  }
}
