/* .hrAlign {
  margin-top: 35.3rem !important;
} */

body::-webkit-scrollbar {
  display: none;
}

#search button#input-group-dropdown-1 {
  padding: 1rem;
  border-radius: 50px 0px 0px 50px;
  background-color: #212529;
  color: #fff;
}

#search input.form-control {
  border-radius: 0px 50px 50px 0px;
}

#search .ms-5 {
  margin-left: 18.7rem !important;
}

#search .w-50 {
  width: 63% !important;
}

#search p.text-secondary.fw-bold,
#search ul.list-group.list-group-flush {
  font-size: 12px;
}

#search p.text-secondary.fw-bold {
  color: black !important;
}

#search .py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.filterDropDown {
  width: 16% !important;
}

.rounded-start {
  border-bottom-left-radius: 50px !important;
  border-top-left-radius: 50px !important;
}

.searchBtn {
  font-size: 25px !important;
  font-weight: 400 !important;
}

.contentAnimation {
  transition: 100s !important;
}

/* App.css */
.MuiDataGrid-columnHeaderCheckbox {
  opacity: 0 !important;
  pointer-events: none;
}

.filterScroll::-webkit-scrollbar {
  width: 9px;
}

.filterScroll {
  scrollbar-width: thin;
  scrollbar-color: darkgray lightgray;
}

.filterScroll::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 6px;
  border: 3px solid darkgray;
}

.filterScroll::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 6px;
  border: 3px solid lightgray;
}

.MuiTableCell-sizeSmall {
  padding: 0px 24px 0px 16px !important;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeSmall.css-1tyq1c6-MuiTableCell-root {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.css-y7600o-MuiTypography-root {
  font-size: 0.7rem !important;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6.5px 14px !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0px 16px 14px !important;
}

/* @media only screen and (min-width: 360px) and (max-width: 576px) { */

@media only screen and (min-width: 360px) and (max-width: 576px) {
  /* small {
    font-size: 0.45rem !important;
  } */

  #searchBox {
    width: 100% !important;
  }

  #searchBox .p-3 {
    padding: 0.4rem !important;
  }

  #searchBox select {
    width: 31% !important;
    font-size: 0.5rem !important;
    padding: 0.5rem !important;
  }

  #searchBox input {
    width: 69% !important;
    font-size: 0.5rem !important;
  }

  .searchBox h3 {
    font-size: 1rem !important;
  }

  #section-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  #search .w-50 {
    width: 64% !important;
  }

  #search .ms-5 {
    margin-left: 5.8rem !important;
  }

  #search p.text-secondary.fw-bold,
  #search ul.list-group.list-group-flush {
    font-size: 7px !important;
  }

  .searchBtn {
    font-size: 15px !important;
  }

  .subTitle {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  #search .ms-5 {
    margin-left: 10rem !important;
  }

  #search .w-50 {
    width: 67% !important;
  }

  .searchBtn {
    font-size: 15px !important;
  }
}

/* @media (min-width: 768px){
    small {
        font-size: .675em !important;
    }

    #searchBox {
        width: 100% !important;
    }

    #searchBox select {
        width: 25% !important;
    }

    #searchBox input {
        width: 75% !important;
    }

    #section-2 {
        padding-left: 2rem!important;
        padding-right: 2rem!important;
    }

    #search .w-50 {
        width: 71% !important;
    }
    #search .ms-5 {
        margin-left: 11rem !important;
    }
}

@media (min-width: 992px){

    #searchBox {
        width: 100% !important;
    }

    #searchBox select {
        width: 15% !important;
    }

    #searchBox input {
        width: 85% !important;
    }

    #section-2 {
        padding-left: 2rem!important;
        padding-right: 2rem!important;
    }

    #search .w-50 {
        width: 83% !important;
    }
    #search .ms-5 {
        margin-left: 8.7rem !important;
    }
}  */